@import url('https://cdn.rawgit.com/moonspam/NanumSquare/master/nanumsquare.css');

.header {
    position: fixed;
    font-family: 'NanumSquare', sans-serif;
    font-size: 40px;
    font-weight: 400;
    width: 100%;
    height: 100px;
    top: 0px;
    z-index: 1;
}